@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-bgPrimary font-sans text-base text-tBase;
}

@layer base {
  :root {
    --color-primary: #fff;
    --color-secondary: #f8f9fd;
    --color-tertiary: #f2f4fc;
    --color-neutral: #e6eaf2;
    --color-accent: #26cad3;
    --color-text-base: #001f6f;
    --color-bg-primary: #001f6f;
    --color-bg-secondary: #fff;
  }

  /* * {
    @apply transition-colors duration-500 ease-in-out;
  } */

  h5 {
    @apply text-2xl;
  }

  h4 {
    @apply text-s3xl lg:text-3xl;
  }

  h3 {
    @apply text-s4xl lg:text-4xl;
  }

  h2 {
    @apply text-s5xl lg:text-5xl;
  }

  h1 {
    @apply text-s6xl lg:text-6xl;
  }

  p,
  li {
    @apply text-base;
  }

  .theme-large-text p {
    @apply text-lg;
  }

  .theme-large-text h5 {
    @apply text-3xl;
  }

  .theme-large-text h4 {
    @apply text-s4xl lg:text-4xl;
  }

  .px-genu {
    @apply px-4 md:px-6 lg:px-8;
  }
}

/* Custom table class seeing as border-collapse doesn't allow for rounded corners */
.genu-tables figure.table {
  @apply w-full overflow-hidden rounded-lg border border-bgPrimary;
}
.genu-tables table {
  @apply border-collapse text-base;
}
.genu-tables table thead {
  @apply bg-tertiary;
}
.genu-tables td {
  @apply border-r border-bgPrimary px-6 py-3 align-top;
}
.genu-tables th {
  @apply border-r border-bgPrimary px-6 py-3 align-top;
}
.genu-tables th:last-child {
  @apply border-none;
}
.genu-tables td:last-child {
  @apply border-none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.pXl {
  color: var(--color-text-base);
  font-size: 2rem;
  line-height: 2.75rem;
}

.theme-contrast {
  --color-primary: #fff;
  --color-secondary: #f8f9fd;
  --color-tertiary: #f2f4fc;
  --color-neutral: #515151;
  --color-accent: #fff;
  --color-text-base: #000;
  --color-bg-primary: #000;
  --color-bg-secondary: #000;
}
